import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Heading,
  Layout,
  Stepper,
} from '@enterprise-ui/canvas-ui-react'
import {
  CaseStatus,
  CaseSubTypes,
  CaseType,
  FieldsMapping,
  FormSections,
} from '../Services/ConstantData'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import * as Yup from 'yup'
import {
  CREATE_CASE,
  CreateCase,
  DocPrefixMapsResponse,
  GET_DOCPREFIXMAPS,
  GET_INVOICE,
  GET_INVOICE_ITEM_DETAILS,
  GET_SHORTAGE_DPCI_DETAILS,
  GET_TAP_WORKFLOW,
  GET_USERPROFILES,
  GET_VENDOR,
  GET_WORKFLOWS,
  GetInvoiceItemDetails,
  GetInvoicePayments,
  GetShortageDPCI,
  GetUserProfiles,
  InvoiceRequest,
  NewCaseRequest,
  TapWorkflowRequest,
  TapWorkflowResponse,
  VendorRequest,
  VendorResponse,
  WorkFlowResponse,
} from '../Common/Queries'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { EnvConfig } from '../../../config/appConfig'
import { useEnv } from '@praxis/component-runtime-env'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import { useAuth } from '@praxis/component-auth'
import VendorDetails from '../Common/VendorDetails'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import CancelModel from '../Common/CancelModal'
import {
  CLAIM_HEADING,
  DATE_FORMAT,
  RESET,
  SET_FIELD,
} from '../Common/Constants'
import _ from 'lodash'
import SpinnerLoad from '../Common/SpinnerLoad'
import {
  findCaseSubTypes,
  findDocumentPrefixMapping,
  findDocumentSubTypeIdValues,
  findDocumentTypeValues,
} from '../Services/DocumentPrefixMapping'
import ClaimDetails from '../Common/DisputeDetails'
import DocumentDetails from '../Claim/ClaimDetails'
import {
  GET_SEARCH_RESULTS,
  GetSearchResults,
  SearchVariable,
} from '../../Dashboard/Queries'
import { findRequired } from '../Services/CheckRequired'
import {
  findTapWorkflowMatch,
  findWorkflowValue,
} from '../Services/WorkflowMapping'
import moment from 'moment'
import { findClaimFields } from '../Services/ClaimDisplayFields'
import { findReasonCodeValues } from '../Services/ReasonMapping'
import ReceiptDetails from '../Common/ReceiptDetails'
import useAttachment from '../Hooks/useAttachment'
import WorkflowDetails from '../Common/WorkflowDetails'
import AttachmentDetails from '../Common/AttachmentDetails'
import ShortageDetails from '../Claim/ShortageDetails'
import CaseSummaryTracker from '../Claim/TclaimCaseSummary'
import SaveTimelineModal from '../Common/SaveTimelineModal'
import CrVendorDetails from '../Claim/CrVendorDetails'
import DuplicateCheck from '../Common/DuplicateCheck'

const TMTClaimCreate: React.FC = () => {
  const env = useEnv() as EnvConfig
  const { userProfile } = useProfile()
  const [assignedUserValues, setAssignedUserValues] = useState<any>([])
  const cTS = new Date().toISOString()
  const { session } = useAuth()
  const [state, dispatch] = useGlobalForm()
  const client = useApolloClient()
  const userName = session?.userInfo?.fullName ?? ''
  const [currentFormSection, setCurrentFormSection] = useState(
    FormSections.VENDOR
  )
  const [completionStatus, setCompletionStatus] = useState<any>({})
  const [searchState, setSearchState] = useState<string>('')

  const [vendorDetails, setVendorDetails] = useState<any>()
  const [importVendor, setImportVendor] = useState<boolean>(false)
  const [filteredCaseSubTypeValues, setFilteredCaseSubTypeValues] =
    useState<any>([])
  const [merchWorkflow, setMerchWorkflow] = useState<string>('')
  const [filteredDocTypeValues, setFilteredDocTypeValues] = useState<any>([])
  const [filteredDocSubTypeValues, setFilteredDocSubTypeValues] = useState<any>(
    []
  )
  const [reasonMappingValues, setReasonMappingValues] = useState<any>([])
  const [filteredDisplayFields, setFilteredDisplayFields] = useState<any>()
  const [filteredRequiredFields, setFilteredRequiredFields] = useState<any>()
  const [invoiceItemDetails, setInvoiceItemDetails] = useState<any>([])
  const [isPredistro, setIsPredistro] = useState<boolean>(false)
  const [shortageInvoiceItemDetails, setShortageInvoiceItemDetails] =
    useState<any>([])
  const cDesc = `${'Created a New '}${CaseType.INTERNALPROCESSING}${' Case '}`

  const schema = Yup.object().shape<NewCaseRequest>({
    originalDocumentNumber: Yup.string().test(
      'Fine',
      'Enter Document Id',
      function (value) {
        if (!state.isCarrierVendor && value === undefined) {
          return false
        }
        return true
      }
    ),
    vendorNumber: Yup.number().required('Enter Valid Vendor #'),
    caseSubType: Yup.string().required('Choose Case Type Code'),
    documentTypeId: Yup.number().test(
      'Fine',
      'Choose Doc Type',
      function (value) {
        if (importVendor && value === undefined) {
          return false
        }
        return true
      }
    ),
    reasonCode: Yup.string().test('Fine', 'Choose Reason', function (value) {
      if (validationFields?.reasonCode === 'Y' && value === undefined) {
        return false
      }
      return true
    }),
    departmentId: Yup.number()
      .test('Fine', 'Enter Valid Department', function (value) {
        if (value === undefined && filteredRequiredFields?.departmentId) {
          return false
        }
        return true
      })
      .test(
        'Fine',
        'Not a valid Department number, please try again',
        function (value) {
          if (value === null || value === undefined) {
            return false
          }
          if (state.departmentCheck === false) {
            return false
          }
          return true
        }
      ),
    classId: Yup.number()
      .test('Fine', 'Enter Valid ClassId', function (value) {
        if (value === undefined && filteredRequiredFields?.classId) {
          return false
        }
        return true
      })
      .test(
        'Fine',
        'Not a valid Department number - Class number combination , please try again.',
        function (value) {
          if (value === null || value === undefined) {
            return true
          }
          if (state.departmentClassCheck === false) {
            return false
          }
          return true
        }
      ),
    claimDetails: Yup.object().shape({
      carrierVendorContactEmail: Yup.string().email('Enter a valid Email'),
      carrierVendorId: Yup.string().test(
        'Fine',
        'Please enter Valid Carrier Vendor #',
        function (value) {
          const context = this.options.context as any
          if (context?.claimDetails?.isMappedVendor && value === undefined) {
            return false
          }
          return true
        }
      ),
      shipDate: Yup.string()
        .required('Please enter the ship date')
        .test(
          'Date grater the 270 days',
          'Ship Date should not be older than 270 days',
          function (value) {
            const shipDate = moment(value).format('MM/DD/YYYY')
            const currentDay = moment().startOf('day')
            const duration = moment.duration(currentDay.diff(shipDate)).asDays()
            if (duration > 270) return false
            return true
          }
        ),
      carrierScacCode: Yup.string().test(
        'Fine',
        'Enter Valid Scac Code',
        function (value) {
          if (state.isCarrierVendor && value === undefined) {
            return false
          }
          return true
        }
      ),
      retailAmount: Yup.number()
        .test(
          'Fine',
          'Please enter a retail amount 0 or greater',
          function (value) {
            if (value === undefined && filteredRequiredFields?.retailAmount) {
              return false
            }
            return true
          }
        )
        .test(
          'Fine',
          'Please enter a retail amount 0 or greater',
          function (value) {
            if (value !== undefined && value < 0) {
              return false
            }
            return true
          }
        ),
      invoiceDate: Yup.string().test(
        'Fine',
        'Enter Invoice Date',
        function (value) {
          if (value === undefined && filteredRequiredFields?.invoiceDate) {
            return false
          }
          return true
        }
      ),
      receiptDate: Yup.string().test(
        'Fine',
        'Enter Receipt Date',
        function (value) {
          if (value === undefined && filteredRequiredFields?.receiptDate) {
            return false
          }
          return true
        }
      ),
      cartonsBilled: Yup.number()
        .test('Fine', 'Enter the number of cartons billed', function (value) {
          if (value === undefined && filteredRequiredFields?.cartonsBilled) {
            return false
          }
          return true
        })
        .test('Fine', 'Enter the cartons in 0 or greater', function (value) {
          if (value !== undefined && value < 0) {
            return false
          }
          return true
        }),
      cartonsReceived: Yup.number()
        .min(0, 'Please enter a valid Cartons')
        .test('Fine', 'Enter the number of cartons received', function (value) {
          if (value === undefined && filteredRequiredFields?.cartonsReceived) {
            return false
          }
          return true
        }),
      shortageCartons: Yup.number()
        .positive('Please enter a valid Cartons')
        .test('Fine', 'Check carton Qty & Cost', function (value) {
          if (value === undefined && filteredRequiredFields?.shortageCartons) {
            return false
          }
          return true
        }),
      shortageAmount: Yup.number()
        .positive('Please enter a valid Amount')
        .test('Fine', 'Check carton Qty & Cost', function (value) {
          if (value === undefined && filteredRequiredFields?.shortageAmount) {
            return false
          }
          return true
        })
        .test(
          'Fine',
          'Shortage Amount cannot be greater than the Original Document Amount',
          function (value) {
            const context = this.options.context as any
            if (
              context?.originalDocumentAmount &&
              value > context?.originalDocumentAmount &&
              !importVendor
            ) {
              return false
            }
            return true
          }
        ),
      trackingNumber: Yup.number().test(
        'Fine',
        'Enter Valid Tracking number',
        function (value) {
          if (value === undefined && filteredRequiredFields?.trackingNumber) {
            return false
          }
          return true
        }
      ),
      trailerNumber: Yup.string().test(
        'Fine',
        'Enter Valid Trailer number',
        function (value) {
          if (value === undefined && filteredRequiredFields?.trailerNumber) {
            return false
          }
          return true
        }
      ),
      trailerType: Yup.string().test(
        'Fine',
        'Enter Valid Trailer type',
        function (value) {
          if (value === undefined && filteredRequiredFields?.trailerType) {
            return false
          }
          return true
        }
      ),
      claimType: Yup.string().test(
        'Fine',
        'Enter Valid Claim type',
        function (value) {
          if (value === undefined && filteredRequiredFields?.claimType) {
            return false
          }
          return true
        }
      ),
    }),
    purchaseOrderNumber: Yup.number()
      .test('Fine', 'Enter Valid Purchase Order #', function (value) {
        if (value === undefined && state.isPORequired) {
          return false
        }
        return true
      })
      .test(
        'Fine',
        'PO length should be either from 1-7 or 11',
        function (value) {
          if (value) {
            var POValueLength = value?.toString().length
            if (
              (POValueLength >= 1 && POValueLength <= 7) ||
              POValueLength === 11
            ) {
              return true
            } else {
              return false
            }
          }
          return true
        }
      ),
    locationId: Yup.number().test(
      'Fine',
      'Enter Valid Location',
      function (value) {
        if (value === undefined && filteredRequiredFields?.locationId) {
          return false
        }
        return true
      }
    ),
    originalDocumentAmount: Yup.number()
      .positive('Please enter a valid Document Amount')
      .test('Fine', 'Please enter a valid Document Amount', function (value) {
        if (
          value === undefined &&
          !importVendor &&
          !state.isCarrierVendor &&
          state.isSourceDocumentAmountRequired
        ) {
          return false
        }
        return true
      }),
    disputedDocumentAmount: Yup.number()
      .positive('Please enter Claim amount to Dispute')
      .test('Fine', 'Please enter Claim amount to Dispute', function (value) {
        if (
          value === undefined &&
          filteredRequiredFields?.disputedDocumentAmount
        ) {
          return false
        }
        return true
      })
      .test(
        'Fine',
        `Claim Amount should be greater than $${env.amountValidation.minClaimAmount}`,
        function (value) {
          if (
            validationFields?.amountValidation === 'Y' &&
            !importVendor &&
            value < env.amountValidation.minClaimAmount
          ) {
            return false
          }
          return true
        }
      )
      .test(
        'Fine',
        'Claim Amount cannot be greater than the Original Document Amount',
        function (value) {
          const context = this.options.context as any
          if (
            context?.originalDocumentAmount &&
            value > context?.originalDocumentAmount &&
            !importVendor
          ) {
            return false
          }
          return true
        }
      ),
  })
  const formik = useFormik<NewCaseRequest>({
    initialValues: {
      caseType: CaseType.INTERNALPROCESSING,
      originalDocumentNumber: '',
      status: CaseStatus.NEW,
      claimDetails: {
        carrierScacCode: '',
        isMappedVendor: false,
        itemLevelShortage: [],
        claimReceipts: [],
      },
      receiptId: '',
      auditEntries: {
        activityDescription: cDesc,
        userId: userName,
        status: CaseStatus.NEW,
        activityTimestamp: cTS,
      },
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema<NewCaseRequest>(
          values,
          validationSchema,
          true,
          values
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },

    onSubmit: async (values) => {
      await createClaim({
        variables: {
          input: values,
        },
      })
    },
  })

  const formValues = formik.values

  const { data: docprefixvalues } = useQuery<DocPrefixMapsResponse>(
    GET_DOCPREFIXMAPS,
    { fetchPolicy: 'network-only' }
  )

  const { data: datawf } = useQuery<WorkFlowResponse>(GET_WORKFLOWS, {
    fetchPolicy: 'network-only',
  })

  const SectionInitState = {
    vendor: false,
    carrier: false,
    document: false,
    claim: false,
    shortage: false,
    attachment: false,
  }

  const handleReset = () => {
    formik.resetForm()
    setMerchWorkflow('')
    setCurrentFormSection(FormSections.VENDOR)
    setCompletionStatus(SectionInitState)
    dispatch({
      type: RESET,
    })
  }

  const getVendor = async () => {
    setSearchState('vendorLoading')
    try {
      const { data } = await client.query<VendorResponse, VendorRequest>({
        query: GET_VENDOR,
        variables: {
          id: formValues.vendorNumber,
        },
        fetchPolicy: 'network-only',
      })
      const getVendorDetails = data?.getVendor
      if (getVendorDetails !== null) {
        if (getVendorDetails.vendorTypeCode === 'M') {
          setVendorDetails(data?.getVendor)
          setSearchState('vendorSuccess')
          formik.setFieldValue('vendorName', getVendorDetails?.vendorName)
          formik.setFieldValue('vendorType', getVendorDetails?.vendorTypeName)
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'vendorIdHintText', value: 'Valid Vendor Number!' },
              { id: 'isVendorNextFlag', value: true },
              { id: 'requestType', value: CaseType.INTERNALPROCESSING },
              { id: 'cvendorNbrflag', value: true },
              // { id: 'isCarrierVendor', value: true },
              // { id: 'isCrVendor', value: true },
            ],
          })
          if (getVendorDetails?.importVendorFlag === 'Y') {
            setImportVendor(true)
          } else {
            setImportVendor(false)
          }
        } else {
          setSearchState('vendorError')
          formik.setFieldError('vendorNumber', 'Enter Valid Merch Vendor!')
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isVendorNextFlag', value: false }],
          })
        }
      } else {
        setVendorDetails(null)
        console.log('No Data Found')
      }
    } catch (errors: any) {
      setSearchState('vendorError')
      console.log('Error', errors?.message)
      formik.setFieldError('vendorNumber', 'Not Valid Vendor, Please try again')
    }
  }

  const getCrVendor = async () => {
    setSearchState('crVendorLoading')
    try {
      const { data } = await client.query<VendorResponse, VendorRequest>({
        query: GET_VENDOR,
        variables: {
          id: Number(formValues.claimDetails?.carrierVendorId),
        },
        fetchPolicy: 'network-only',
      })
      const getCrVendorDetails = data?.getVendor
      if (getCrVendorDetails !== null) {
        if (getCrVendorDetails.vendorTypeCode === 'T') {
          formik.setFieldValue(
            'claimDetails.carrierVendorName',
            getCrVendorDetails?.vendorName
          )
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isCrVendorNextFlag', value: true },
              { id: 'isClaimNextFlag', value: false },
            ],
          })
        } else {
          setSearchState('vendorError')
          formik.setFieldError(
            'claimDetails.carrierVendorId',
            'Not a Valid Transport/Carrier Vendor!'
          )

          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isCrVendorNextFlag', value: false },
              { id: 'isClaimNextFlag', value: true },
            ],
          })
        }
      } else {
        console.log('No Data Found')
      }
    } catch (errors: any) {
      setSearchState('vendorError')
      formik.setFieldValue('claimDetails.carrierVendorName', undefined)
      console.log('Error', errors?.message)
      formik.setFieldError(
        'claimDetails.carrierVendorId',
        'Not a Valid Vendor, Please try again'
      )
    }
  }

  function validateFields(errors: any, fields: string[]): boolean {
    let mappedKeys: (string | string[])[] = []
    Object.keys(errors).forEach(function (key) {
      if (_.isObject(errors[key])) {
        Object.keys(errors[key]).forEach(function (subKey) {
          mappedKeys.push(subKey)
        })
      } else {
        mappedKeys.push(key)
      }
    })
    return !fields.some((el) => mappedKeys.includes(el))
  }

  const handleVendorSearch = () => {
    formik.validateForm().then((res) => {
      const fields = ['vendorNumber']
      if (validateFields(res, fields)) {
        formik.setErrors({})
        getVendor()
      } else {
        formik.setErrors({
          vendorNumber: res.vendorNumber,
        })
      }
    })
  }

  const handleCrVendorSearch = () => {
    if (formik.values.claimDetails?.carrierVendorId) {
      formik.setFieldError('claimDetails.carrierVendorId', undefined)
      getCrVendor()
    } else {
      formik.setFieldError(
        'claimDetails.carrierVendorId',
        'Please enter Valid Carrier Vendor #'
      )
      formik.setFieldValue('claimDetails.carrierVendorName', undefined)
    }
  }

  const onVendorNextClick = () => {
    formik.validateForm().then((res) => {
      const fields = ['vendorNumber']
      if (validateFields(res, fields)) {
        let caseSubTypeValues: any
        caseSubTypeValues = findCaseSubTypes(
          _.filter(CaseSubTypes, function (caseSubTypeValues) {
            return (
              caseSubTypeValues.caseType === formValues.caseType &&
              caseSubTypeValues.carrierVendor === 'Y' &&
              caseSubTypeValues.ImportFlag === 'N'
            )
          })
        )
        formik.setFieldValue('caseSubType', caseSubTypeValues[0].value)
        setFilteredCaseSubTypeValues(caseSubTypeValues)
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'isVendShowable', value: true }],
        })
        formik.setErrors({})
        setCompletionStatus({ ...completionStatus, vendor: true })
        setCurrentFormSection(FormSections.DOCUMENT)
      } else {
        formik.setFieldError('vendorNumber', res?.vendorNumber)
      }
    })
  }

  const onCarrierNextClick = () => {
    formik.validateForm().then((res) => {
      const fields = ['carrierVendorId']
      if (validateFields(res, fields)) {
        formik.setErrors({})
        setCompletionStatus({ ...completionStatus, carrier: true })
        setCurrentFormSection(FormSections.CLAIM)
      } else {
        formik.setFieldError(
          'carrierVendorId',
          Object(res?.claimDetails).carrierVendorId
        )
      }
    })
  }

  const handleSearch = () => {
    if (
      !state.isCarrierVendor ||
      (state.isCarrierVendor && formValues?.originalDocumentNumber)
    ) {
      formik.validateForm().then((res) => {
        const fields = ['originalDocumentNumber']
        if (validateFields(res, fields)) {
          if (!importVendor) {
            fetchTapDetails()
          }
          duplicateCaseCheck()
          formik.setErrors({})
        } else {
          formik.setErrors({
            originalDocumentNumber: res.originalDocumentNumber,
          })
        }
      })
    }
  }

  const fetchTapDetails = async () => {
    setSearchState('loading')
    try {
      const { data } = await client.query<GetInvoicePayments, InvoiceRequest>({
        query: GET_INVOICE,
        variables: {
          vendorId: formValues?.vendorNumber,
          documentId: formValues?.originalDocumentNumber,
        },
        fetchPolicy: 'network-only',
      })
      const { data: itemData } = await client.query<
        GetInvoiceItemDetails,
        InvoiceRequest
      >({
        query: GET_INVOICE_ITEM_DETAILS,
        variables: {
          vendorId: formValues?.vendorNumber,
          documentId: formValues?.originalDocumentNumber,
        },
        fetchPolicy: 'network-only',
      })

      const { data: shortageDPCIData } = await client.query<
        GetShortageDPCI,
        InvoiceRequest
      >({
        query: GET_SHORTAGE_DPCI_DETAILS,
        variables: {
          documentId: formValues?.originalDocumentNumber,
          vendorId: formValues?.vendorNumber,
          receiptId:
            data?.getInvoiceDetails?.receipts
              ?.map((receipt) => receipt.receiptNumber)
              .join(',') || '',
        },
        fetchPolicy: 'network-only',
      })
      const getInvoiceItemDetails = itemData?.getInvoiceFullDetails
      const getInvoiceDetails = data?.getInvoiceDetails
      const getPaymentDetails = data?.getInvoiceDetails?.payment
      const getReceiptDetails = data?.getInvoiceDetails?.receipts
      const getShortageDPCI = shortageDPCIData?.getShortageDPCI
      if (shortageDPCIData?.getShortageDPCI.at(0)?.casepackReceived === 1) {
        setIsPredistro(true)
      }
      if (getInvoiceItemDetails != null && getInvoiceItemDetails.length > 0) {
        let initialShortageDetails = getShortageDPCI?.map((shortage: any) => {
          return {
            id: _.uniqueId(),
            dpci: shortage.dpci,
            department: shortage.dpci.split('-')[0],
            classId: shortage.dpci.split('-')[1],
            item: shortage.dpci.split('-')[2],
            invoiceQuantity: shortage.invoiceUnit,
            quantityShortage:
              shortage.shortageQty !== '0' ? shortage.shortageQty : '',
            unitCost: shortage.invoiceItemCost,
            casePack: shortage.casepackReceived,
            cartonCount: shortage.cartonCount,
            shortageCarton:
              shortage.shortageCtn !== '0' ? shortage.shortageCtn : '',
            shortageCost:
              shortage.shortageCost !== '0' ? shortage.shortageCost : '',
          }
        })
        setInvoiceItemDetails(initialShortageDetails)
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'invoiceItemDetails', value: true }],
        })

        let shortageDetails = getShortageDPCI
          ?.filter((shortage: any) => shortage.dpciType === 'Shortage')
          ?.map((shortage: any) => {
            return {
              id: _.uniqueId(),
              dpci: shortage.dpci,
              department: shortage.dpci.split('-')[0],
              classId: shortage.dpci.split('-')[1],
              item: shortage.dpci.split('-')[2],
              invoiceQuantity: shortage.invoiceUnit,
              quantityShortage: shortage.shortageQty,
              unitCost: shortage.invoiceItemCost,
              casePack: shortage.casepackReceived,
              cartonCount: shortage.cartonCount,
              shortageCarton: shortage.shortageCtn,
              shortageCost: shortage.shortageCost,
            }
          })
        setShortageInvoiceItemDetails(shortageDetails)
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'shortageInvoiceItemDetails', value: true }],
        })
      }

      if (getInvoiceDetails !== null) {
        setSearchState('success')
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'documentHintText', value: 'Document Number Found' },
            { id: 'disputeSectionFlag', value: true },
            { id: 'departmentCheck', value: true },
          ],
        })
        formik.setFieldValue('departmentId', getInvoiceDetails?.deptId)
        formik.setFieldValue('purchaseOrderNumber', getInvoiceDetails?.poId)
        formik.setFieldValue('locationId', getInvoiceDetails?.locId)
        formik.setFieldValue(
          'originalDocumentAmount',
          getInvoiceDetails?.originalDocAmount
        )
        formik.setFieldValue(
          'claimDetails.invoiceDate',
          getInvoiceDetails?.invoiceDate
        )
        if (getPaymentDetails !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'documentHintText', value: 'Document Number Found' },
              { id: 'paymentSectionFlag', value: true },
              { id: 'disputeSectionFlag', value: true },
            ],
          })
          formik.setFieldValue(
            'originalCheckAmount',
            getPaymentDetails?.checkAmount
          )
          formik.setFieldValue(
            'originalCheckNumber',
            getPaymentDetails?.checkNumber
          )
          formik.setFieldValue(
            'originalCheckDate',
            getPaymentDetails?.checkDate
          )
        } else {
          formik.setFieldValue('originalCheckAmount', undefined)
          formik.setFieldValue('originalCheckNumber', undefined)
          formik.setFieldValue('originalCheckDate', undefined)
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'paymentSectionFlag', value: false }],
          })
        }
        const receipts = getReceiptDetails.map(
          (receipt) => receipt.receiptNumber
        )

        if (receipts?.length !== 0) {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'documentHintText', value: 'Document Number Found' },
              { id: 'disputeSectionFlag', value: true },
              { id: 'receiptsSectionFlag', value: true },
            ],
          })
          formik.setFieldValue('receipts', [...receipts])
        } else {
          formik.setFieldValue('receipts', undefined)
        }
      } else {
        setSearchState('notfound')
      }
    } catch (errors) {
      setSearchState('error')
      dispatch({
        type: SET_FIELD,
        payload: [
          { id: 'disputeSectionFlag', value: false },
          { id: 'documentHintText', value: 'Document Number Not Found' },
          { id: 'paymentSectionFlag', value: false },
        ],
      })
      formik.setFieldValue('departmentId', undefined)
      formik.setFieldValue('purchaseOrderNumber', undefined)
      formik.setFieldValue('locationId', undefined)
      formik.setFieldValue('originalDocumentAmount', undefined)
      formik.setFieldValue('originalCheckAmount', undefined)
      formik.setFieldValue('originalCheckNumber', undefined)
      formik.setFieldValue('originalCheckDate', undefined)
      formik.setFieldValue('claimDetails.invoiceDate', undefined)
    }
  }

  const duplicateCaseCheck = async () => {
    try {
      const caseType = formValues?.caseType?.replace(/\//g, '\\/') || ''
      const originalDocumentNumber =
        formValues?.originalDocumentNumber?.replace(/\//g, '\\/') || ''
      const { data } = await client.query<GetSearchResults, SearchVariable>({
        query: GET_SEARCH_RESULTS,
        variables: {
          query: `vendor_number: (${formValues?.vendorNumber}) AND original_document_number.keyword: (${originalDocumentNumber}) AND case_type: (${caseType}) AND status: (${CaseStatus.NEW} OR ${CaseStatus.INPROGRESS} OR ${CaseStatus.REOPEN} OR ${CaseStatus.AWAITINGINFO})`,
          sort: 'create_timestamp',
          page: 1,
          perPage: 10,
          vendorId: `${formValues?.vendorNumber}`,
        },
        fetchPolicy: 'network-only',
      })
      const getSearchResults = data?.getSearchResults?.cases
      if (getSearchResults !== null) {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'showDialog', value: true },
            { id: 'activeDuplicate', value: true },
          ],
        })
      } else {
        duplicateDocumentCheck()
      }
    } catch (errors: any) {
      console.log('duplicateCaseCheck', errors?.message)
    }
  }

  const duplicateDocumentCheck = async () => {
    try {
      const caseType = formValues?.caseType?.replace(/\//g, '\\/') || ''
      const originalDocumentNumber =
        formValues?.originalDocumentNumber?.replace(/\//g, '\\/') || ''
      const { data } = await client.query<GetSearchResults, SearchVariable>({
        query: GET_SEARCH_RESULTS,
        variables: {
          query: `vendor_number: (${formValues?.vendorNumber}) AND original_document_number.keyword: (${originalDocumentNumber}) AND case_type: (${caseType})`,
          sort: 'create_timestamp',
          page: 1,
          perPage: 10,
          vendorId: `${formValues?.vendorNumber}`,
        },
        fetchPolicy: 'network-only',
      })
      const getSearchResults = data?.getSearchResults?.cases
      if (!state.activeDuplicate) {
        if (getSearchResults !== null) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'showDialog', value: true }],
          })
        } else {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isDocNextFlag', value: true },
              { id: 'documentFound', value: true },
            ],
          })
        }
      }
    } catch (errors: any) {
      console.log('duplicateDocumentCheck', errors?.message)
    }
  }

  const validationFields = findRequired(formik.values)

  const onClaimNextClick = () => {
    const isPORequired =
      (!importVendor &&
        !state.isCarrierVendor &&
        validationFields?.PO === 'Y') ||
      (state.isCarrierVendor && validationFields?.CarrierPO === 'Y')
        ? true
        : false
    formik.validateForm().then((res) => {
      let fields = ['originalDocumentNumber', 'caseSubType']
      if (state.isCarrierVendor) {
        fields = ['caseSubType']
      }
      if (importVendor) {
        fields = ['originalDocumentNumber', 'caseSubType', 'documentTypeId']
      }
      if (validateFields(res, fields)) {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'showDocuments', value: true },
            { id: 'isCrVendor', value: false },

            {
              id: 'isPORequired',
              value: isPORequired,
            },

            {
              id: 'documentFound',
              value:
                state.isCarrierVendor && !formValues?.originalDocumentNumber
                  ? true
                  : state.documentFound,
            },
            {
              id: 'documentPrefix',
              value: findDocumentPrefixMapping(
                docprefixvalues?.getDocumentTypeMaps,
                formik?.values?.originalDocumentNumber,
                formik?.values?.caseType
              ),
            },
          ],
        })
        formik.setFieldValue('claimDetails.isMappedVendor', false)
        if (
          !state.isCarrierVendor &&
          formik.values.caseSubType === 'Transport Claims'
        ) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isCrVendor', value: true }],
          })
          formik.setFieldValue('claimDetails.isMappedVendor', true)
        }
        formik.setErrors({})
        const mappingWorkflow = findWorkflowValue(
          docprefixvalues?.getDocumentTypeMaps,
          formik?.values,
          state.documentPrefix,
          false
        )
        formik.setFieldValue('workflow', mappingWorkflow)
        formik.setFieldValue('auditEntries.workflow', mappingWorkflow)
        setMerchWorkflow(mappingWorkflow)
        setCompletionStatus({ ...completionStatus, document: true })
        setCurrentFormSection(FormSections.CARRIER)
      }
    })
  }

  const onReferTapWorkflow = async () => {
    const isTclaim =
      merchWorkflow === 'TCLAIM' &&
      formValues?.caseSubType === 'Transport Claims'
    setSearchState('TapLoading')
    try {
      const { data } = await client.query<
        TapWorkflowResponse,
        TapWorkflowRequest
      >({
        query: GET_TAP_WORKFLOW,
        variables: {
          vendorId: formValues.vendorNumber,
        },
        fetchPolicy: 'network-only',
      })
      const getWorkflow = data?.getTapWorkflow
      if (getWorkflow !== null) {
        formik.setFieldValue(
          'workflow',
          findTapWorkflowMatch(
            datawf?.getWorkflows,
            getWorkflow?.vendorTapWorkflow
          )
        )
        formik.setFieldValue(
          'auditEntries.workflow',
          findTapWorkflowMatch(
            datawf?.getWorkflows,
            getWorkflow?.vendorTapWorkflow
          )
        )
      } else {
        formik.setFieldValue('workflow', isTclaim ? merchWorkflow : 'DEFAULT')
        formik.setFieldValue(
          'auditEntries.workflow',
          isTclaim ? merchWorkflow : 'DEFAULT'
        )
        console.log('Tap Data Found')
      }
    } catch (errors: any) {
      formik.setFieldValue('workflow', isTclaim ? merchWorkflow : 'DEFAULT')
      formik.setFieldValue(
        'auditEntries.workflow',
        isTclaim ? merchWorkflow : 'DEFAULT'
      )
      console.log('Error', errors?.message)
    }
  }

  const onShipDateChange = () => {
    formik.validateForm().then((res) => {
      const fields = ['shipDate']
      if (validateFields(res, fields)) {
        formik.setErrors({})
      }
    })
  }

  const onDocumentNextClick = () => {
    if (formik.values?.claimDetails?.shipDate) {
      const formattedDate = moment(formik.values.claimDetails.shipDate)
        .format('YYYY-MM-DD')
        .concat(DATE_FORMAT)
      formik.setFieldValue('claimDetails.shipDate', formattedDate)
    }
    formik.validateForm().then((res) => {
      let fields = [
        'purchaseOrderNumber',
        'originalDocumentAmount',
        'departmentId',
        'shipDate',
        'classId',
        'locationId',
        'cbdcDocumentDate',
        'retailAmount',
        'invoiceDate',
        'receiptDate',
        'cartonsBilled',
        'cartonsReceived',
        'trailerNumber',
        'trackingNumber',
        'trailerType',
        'claimType',
      ]
      if (validateFields(res, fields)) {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'isDocShowable', value: true },
            { id: 'isCrVendShowable', value: true },
          ],
        })
        formik.setErrors({})
        setCompletionStatus({ ...completionStatus, claim: true })
        setCurrentFormSection(FormSections.SHORTAGE)
      }
      updateAttachmentsToDefaults()
    })
  }

  const handleShortageNextClick = () => {
    formik.validateForm().then((res: any) => {
      let fields = ['shortageCartons', 'shortageAmount']
      if (validateFields(res, fields)) {
        formik.setErrors({})
        setCompletionStatus({
          ...completionStatus,
          shortage: true,
        })
        setCurrentFormSection(FormSections.ATTACHMENT)
      }
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'isShortageNext', value: false }],
      })
    })
  }

  const onWorkflowNextClick = () => {
    setCompletionStatus({ ...completionStatus, attachment: true })
    setCurrentFormSection(FormSections.REVIEW)
  }

  const {
    uploadAttachment,
    updateStatus,
    attachmentDetails,
    updateAttachmentsToDefaults,
  } = useAttachment()

  const createClaim = async (postClaim: any) => {
    const submitStatuses = attachmentDetails.submitStatuses
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'isSubmit', value: true },
        { id: 'creatingCase', value: true },
        { id: 'caseCreatedFlag', value: true },
      ],
    })
    await createCaseClaim(postClaim)
      .then(async (res) => {
        submitStatuses?.push({
          message: `Case Created with Case Number:${res?.data?.createCase.caseId}`,
          status: true,
        })
        updateStatus(submitStatuses)
        await uploadAttachment(
          String(res?.data?.createCase?.caseId),
          formValues.vendorNumber
        ).then(() => {
          dispatch({
            type: SET_FIELD,
            payload: [
              { id: 'isSubmit', value: false },
              { id: 'creatingCase', value: true },
              { id: 'caseCreatedFlag', value: true },
            ],
          })
        })
      })
      .catch(() => {
        submitStatuses?.push({
          message: 'Unable to create case, try again',
          status: false,
        })
        updateStatus(submitStatuses)
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'isSubmit', value: false },
            { id: 'creatingCase', value: true },
            { id: 'caseCreatedFlag', value: false },
          ],
        })
      })
  }

  const [createCaseClaim, claims] = useMutation<
    CreateCase,
    { input: NewCaseRequest }
  >(CREATE_CASE)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useQuery<GetUserProfiles>(GET_USERPROFILES, {
    onCompleted: (data: GetUserProfiles) => {
      const values = data?.getUserProfiles?.map((userProfile: any) => ({
        id: userProfile.userId,
        value: userProfile.userId,
        label: userProfile.fullName,
        vendor: userProfile.isVendor,
      }))
      const userValues = values.filter(function (userValue: any) {
        return !userValue.vendor
      })
      setAssignedUserValues(userValues)
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    setFilteredDocTypeValues([])
    setFilteredDocSubTypeValues([])
    setReasonMappingValues([])
    formik.setFieldValue('documentTypeId', undefined)
    formik.setFieldValue('documentTypeText', '')
    formik.setFieldValue('documentSubTypeId', undefined)
    formik.setFieldValue('documentSubTypeText', '')
    formik.setFieldValue('reasonCode', '')
    formik.setFieldValue('reasonCodeText', '')
    if (formik.values?.caseSubType) {
      setFilteredDisplayFields(
        findClaimFields(FieldsMapping, 'D', formik?.values)
      )
      setFilteredRequiredFields(
        findClaimFields(FieldsMapping, 'R', formik?.values)
      )
      if (docprefixvalues?.getDocumentTypeMaps) {
        setFilteredDocTypeValues(
          findDocumentTypeValues(
            docprefixvalues?.getDocumentTypeMaps,
            formik?.values
          )
        )
        setReasonMappingValues(
          findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.caseSubType, docprefixvalues?.getDocumentTypeMaps])

  useEffect(() => {
    setReasonMappingValues(
      findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
    )
    setFilteredDisplayFields(
      findClaimFields(FieldsMapping, 'D', formik?.values)
    )
    setFilteredRequiredFields(
      findClaimFields(FieldsMapping, 'R', formik?.values)
    )
    setFilteredDocSubTypeValues([])
    formik.setFieldValue('documentSubTypeId', undefined)
    formik.setFieldValue('documentSubTypeText', '')
    formik.setFieldValue('reasonCode', '')
    formik.setFieldValue('reasonCodeText', '')
    if (formik.values?.documentTypeId) {
      setFilteredDocSubTypeValues(
        findDocumentSubTypeIdValues(
          docprefixvalues?.getDocumentTypeMaps,
          formik?.values
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.documentTypeId])

  useEffect(() => {
    setReasonMappingValues(
      findReasonCodeValues(docprefixvalues?.getReasonCodeMaps, formValues)
    )
    setFilteredDisplayFields(
      findClaimFields(FieldsMapping, 'D', formik?.values)
    )
    setFilteredRequiredFields(
      findClaimFields(FieldsMapping, 'R', formik?.values)
    )
    formik.setFieldValue('reasonCode', '')
    formik.setFieldValue('reasonCodeText', '')
    if (formik.values?.documentSubTypeId) {
      setFilteredDocSubTypeValues(
        findDocumentSubTypeIdValues(
          docprefixvalues?.getDocumentTypeMaps,
          formik?.values
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.documentSubTypeId])

  useEffect(() => {
    if (
      merchWorkflow === 'USEVEN' ||
      (formik.values?.caseSubType === 'Transport Claims' &&
        vendorDetails?.vendorTypeCode === 'M')
    ) {
      onReferTapWorkflow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchWorkflow])

  return (
    <Layout.Body includeRail>
      <Grid.Container>
        <Grid.Item sm={12} md={12}>
          <Grid.Container justify="space-between" className="hc-pa-none">
            <Grid.Item justify-content="center">
              <Heading
                size={6}
                className="hc-mt-normal hc-mb-normal hc-ml-none"
              >
                FILE AN INTERNAL PROCESSING CASE
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <Button className="headerButton" onClick={handleReset}>
                RESET FORM
              </Button>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container>
            <Grid.Item sm={12} md={12}>
              <Stepper
                onStepSelect={(event: any, step: any) => {
                  setCurrentFormSection(step.name)
                }}
                activeStep={currentFormSection}
              >
                <Stepper.Item
                  index={1}
                  name={FormSections.VENDOR}
                  completed={completionStatus[FormSections.VENDOR]}
                  clickable
                  onClick={() => {
                    setCurrentFormSection(FormSections.VENDOR)
                  }}
                >
                  Vendor Info
                </Stepper.Item>
                <Stepper.Item
                  index={2}
                  name={FormSections.DOCUMENT}
                  completed={completionStatus[FormSections.DOCUMENT]}
                  clickable
                  onClick={() => {
                    setCurrentFormSection(FormSections.DOCUMENT)
                  }}
                  disabled={!completionStatus[FormSections.VENDOR]}
                >
                  Document Details
                </Stepper.Item>
                <Stepper.Item
                  index={3}
                  name={FormSections.CARRIER}
                  completed={completionStatus[FormSections.CARRIER]}
                  clickable
                  onClick={() => {
                    setCurrentFormSection(FormSections.CARRIER)
                  }}
                  disabled={!completionStatus[FormSections.DOCUMENT]}
                >
                  Carrier Details
                </Stepper.Item>
                <Stepper.Item
                  index={4}
                  name={FormSections.CLAIM}
                  completed={completionStatus[FormSections.CLAIM]}
                  clickable
                  onClick={() => {
                    // handleInvoiceNextClick()
                    setCurrentFormSection(FormSections.CLAIM)
                  }}
                  disabled={!completionStatus[FormSections.CARRIER]}
                >
                  Claim Details
                </Stepper.Item>
                <Stepper.Item
                  index={5}
                  name={FormSections.SHORTAGE}
                  completed={completionStatus[FormSections.SHORTAGE]}
                  clickable
                  onClick={() => {
                    setCurrentFormSection(FormSections.SHORTAGE)
                  }}
                  disabled={!completionStatus[FormSections.CLAIM]}
                >
                  Item Level Details
                </Stepper.Item>
                <Stepper.Item
                  index={6}
                  name={FormSections.ATTACHMENT}
                  completed={completionStatus[FormSections.ATTACHMENT]}
                  clickable
                  onClick={() => {
                    setCurrentFormSection(FormSections.ATTACHMENT)
                  }}
                  disabled={!completionStatus[FormSections.SHORTAGE]}
                >
                  Attachments
                </Stepper.Item>
                <Stepper.Item
                  index={7}
                  name="review"
                  clickable
                  onClick={() => {
                    setCurrentFormSection(FormSections.REVIEW)
                  }}
                  disabled={!completionStatus[FormSections.ATTACHMENT]}
                >
                  Review
                </Stepper.Item>
              </Stepper>
              <Form onSubmit={formik.handleSubmit}>
                {currentFormSection === FormSections.VENDOR && (
                  <Grid.Item className="hc-pt-normal" sm={12} md={10}>
                    <VendorDetails
                      formik={formik}
                      handleVendorSearch={handleVendorSearch}
                    />
                    {!state.isVendShowable && (
                      <div className="hc-pa-normal hc-pb-none">
                        <Grid.Container direction="row-reverse">
                          <Grid.Item className="hc-pt-none">
                            <Button
                              onClick={() => {
                                onVendorNextClick()
                              }}
                              disabled={!state.isVendorNextFlag}
                              type="primary"
                            >
                              Next
                            </Button>
                          </Grid.Item>
                          {searchState === 'vendorLoading' && (
                            <SpinnerLoad case={false} createCase />
                          )}
                        </Grid.Container>
                      </div>
                    )}
                  </Grid.Item>
                )}
                {currentFormSection === FormSections.DOCUMENT && (
                  <Grid.Item className="hc-pt-normal" sm={12} md={10}>
                    <ClaimDetails
                      heading={CLAIM_HEADING}
                      caseSubTypeValues={filteredCaseSubTypeValues}
                      docTypeValues={filteredDocTypeValues}
                      docSubTypeValues={filteredDocSubTypeValues}
                      reasonCodeValues={reasonMappingValues}
                      formik={formik}
                      handleSearch={handleSearch}
                      importVendor={importVendor}
                    />
                    {!state.showDocuments && (
                      <div className="hc-pa-normal hc-pb-none">
                        <Grid.Container
                          direction="row-reverse"
                          className="hc-pb-none"
                        >
                          <Grid.Item className="hc-pt-none">
                            <Button
                              onClick={() => {
                                onClaimNextClick()
                              }}
                              disabled={
                                !state.isDocNextFlag && !state.isCarrierVendor
                              }
                              type="primary"
                            >
                              Next
                            </Button>
                          </Grid.Item>
                          {searchState === 'loading' && (
                            <SpinnerLoad case={false} createCase />
                          )}
                        </Grid.Container>
                      </div>
                    )}
                  </Grid.Item>
                )}
                {currentFormSection === FormSections.CARRIER && (
                  <Grid.Item className="hc-pt-normal" sm={12} md={10}>
                    <CrVendorDetails
                      formik={formik}
                      handleCrVendorSearch={handleCrVendorSearch}
                    />
                    {!state.isDocShowable && (
                      <div className="hc-pa-normal hc-pb-none hc-pt-none">
                        <Grid.Container direction="row-reverse">
                          <Grid.Item className="hc-pt-none">
                            <Button
                              onClick={() => {
                                onCarrierNextClick()
                              }}
                              disabled={state.isClaimNextFlag}
                              type="primary"
                            >
                              Next
                            </Button>
                          </Grid.Item>
                          {searchState === 'crvendorLoading' && (
                            <SpinnerLoad case={false} createCase />
                          )}
                        </Grid.Container>
                      </div>
                    )}
                  </Grid.Item>
                )}
                {currentFormSection === FormSections.CLAIM && (
                  <Grid.Item className="hc-pt-normal" sm={12} md={10}>
                    {!importVendor && (
                      <ReceiptDetails
                        formik={formik}
                        displayCols={filteredDisplayFields}
                        requiredCols={filteredRequiredFields}
                        onShipDateChange={onShipDateChange}
                      />
                    )}
                    <DocumentDetails
                      formik={formik}
                      displayCols={filteredDisplayFields}
                      requiredCols={filteredRequiredFields}
                      importVendor={importVendor}
                    />

                    {!state.isDocShowable && (
                      <div className="hc-pa-normal hc-pb-none hc-pt-none">
                        <Grid.Container direction="row-reverse">
                          <Grid.Item className="hc-pt-none">
                            <Button
                              onClick={() => {
                                onDocumentNextClick()
                              }}
                              disabled={state.isClaimNextFlag}
                              type="primary"
                            >
                              Next
                            </Button>
                          </Grid.Item>
                          {searchState === 'crvendorLoading' && (
                            <SpinnerLoad case={false} createCase />
                          )}
                        </Grid.Container>
                      </div>
                    )}
                  </Grid.Item>
                )}
                {currentFormSection === FormSections.SHORTAGE && (
                  <Grid.Item className="hc-pt-normal" sm={12} md={12}>
                    <ShortageDetails
                      formik={formik}
                      invoiceItems={invoiceItemDetails}
                      isPredistro={isPredistro}
                      shortageInvoiceItems={shortageInvoiceItemDetails}
                      displayCols={filteredDisplayFields}
                      completionStatus={completionStatus}
                      onShortageNextClick={handleShortageNextClick}
                    />
                  </Grid.Item>
                )}
                {currentFormSection === FormSections.ATTACHMENT && (
                  <Grid.Item className="hc-pt-normal" sm={12} md={10}>
                    <WorkflowDetails
                      formik={formik}
                      assignedUserValues={assignedUserValues}
                      workflowValues={datawf?.getWorkflows}
                    />

                    <AttachmentDetails
                      isVendor={userProfile?.isVendor}
                      reasonCode={formValues?.reasonCode}
                    />
                    {state.isDocShowable && (
                      <div className="hc-pa-normal hc-pb-none hc-pt-none">
                        <Grid.Container direction="row-reverse">
                          <Grid.Item className="hc-pt-none">
                            <Button
                              onClick={() => {
                                onWorkflowNextClick()
                              }}
                              disabled={state.isClaimNextFlag}
                              type="primary"
                            >
                              Next
                            </Button>
                          </Grid.Item>
                          {searchState === 'crvendorLoading' && (
                            <SpinnerLoad case={false} createCase />
                          )}
                        </Grid.Container>
                      </div>
                    )}
                  </Grid.Item>
                )}
                {currentFormSection === FormSections.REVIEW && (
                  <>
                    <Grid.Item className="hc-pt-normal" sm={12} md={10}>
                      <CaseSummaryTracker
                        caseSummaryDetails={formValues}
                        importVendor={importVendor}
                        displayCols={filteredDisplayFields}
                      />
                    </Grid.Item>
                    <Grid.Container direction="row-reverse">
                      <Grid.Item hidden={state.isSubmit}>
                        <Button
                          type="secondary"
                          onClick={() => {
                            dispatch({
                              type: SET_FIELD,
                              payload: [{ id: 'isCancel', value: true }],
                            })
                          }}
                          disabled={state.caseCreatedFlag}
                        >
                          Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          type="submit"
                          disabled={
                            state.caseCreatedFlag ||
                            attachmentDetails.attachmentTypeNotSet
                          }
                        >
                          Submit
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </>
                )}
              </Form>
              {(state.creatingCase || claims.loading) && (
                <SaveTimelineModal
                  caseId={claims?.data?.createCase.caseId}
                  vendorNumber={formValues.vendorNumber}
                  redirectUrl={`/cases/${claims?.data?.createCase?.caseId}`}
                />
              )}
            </Grid.Item>
            <CancelModel
              headingText="Cancel Transport Claim Case Creation!"
              heading="Do You Want to Cancel the Transport Claim Creation?"
            />
            <DuplicateCheck />
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}

export default TMTClaimCreate
